import request from '@/utils/request'
//新增广告
export function addOrgConfig(data) {
    return request({
        url: '/movecar/orgConfig/modifyOrgConfig',//请求路径
        method: 'post',
        data
    });
}

//获取广告
export function getOrgConfig(data){
    return request({
        url:'/movecar/orgConfig/getOrgConfigByOrgId',
        method:'get',
        params:data
    })
}
