<template>
    <div >
        <div class="box clearfix admBox"> 
            <el-form :model="ruleForm" :label-position="labelPosition" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <div class="section s1">
                    <div class="left">
                        <div class="title">{{orgName}}-落地页广告</div>
                        <el-divider></el-divider>
                        <el-form-item label="顶部标签" prop="topTitleContext" class="form-item">
                            <el-input v-model="ruleForm.topTitleContext" class="input" placeholder="请输入顶部标签（1-8字符）"></el-input>
                        </el-form-item>
                        <div>
                            <p class="mar-tb10"><span>广告图片</span><span class="red">*</span> <span class="annot-type">注：添加多张图片则按照从左至右顺序进行轮播，最多5张</span></p>
                            <el-upload
                            class="upload-demo"
                            list-type="picture-card"
                            accept=".jpg,.jpeg,.png,.JPG,.JPEG"
                            :before-upload="beforeAvatarUpload"
                            :class="{hide:hideUpload}"
                            :action="action"
                            :headers="importHeaders"
                            :on-remove="handleRemove"
                            :on-success="onSuccess"
                            :on-change="onChange"
                            :on-exceed="handleExceed"
                            :limit="5"
                            :file-list="fileList">
                            <i class="el-icon-plus"></i>
                            <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb，修改一张照片请重新输入所有图片类型</div>
                            </el-upload>
                        </div>
                        <div class="mar-tb10" v-for="(item,index) in ruleForm.elementList" :key="index">
                            <p class="mar-tb10"><span>图片{{index+1}}类型</span><span class="red">*</span> <span class="annot-type">注：H5请填写跳转链接，呼叫请填写拨打电话号码</span></p>
                            <span class="annotation">注：H5请填写跳转链接，呼叫请填写拨打电话号码</span>
                            <el-radio-group v-model="item.picType" class="mar-tb10">
                            <el-radio :label="1">H5</el-radio>
                            <el-radio :label="2">呼叫</el-radio>
                            </el-radio-group>
                            <el-form-item :prop="'elementList.'+ index +'.picLinkContent'" :rules="rules.picLinkContent">
                                <el-input v-model="item.picLinkContent" class="input" placeholder="请输入链接或者电话号码"></el-input>
                                <!-- <p class="el-form-item__error error-tip">请输入链接或者电话号码</p>  -->
                            </el-form-item>                        
                        </div>
                    </div>
                    <div class="right">
                        <div class="title">示例</div>
                        <el-divider></el-divider>
                        <div class="box" style="margin-bottom: 52px;">
                            <img :src="imgUrl1" >
                        </div>
                    </div>
                </div>
                <div class="section s2">
                    <div class="left">
                        <el-divider></el-divider>
                        <div>
                            <div class="mar-tb10" >
                                <p>按钮左：</p>
                                <div>
                                    <el-form-item label="按钮文字" prop="leftButtonName" class="form-item">
                                        <el-input v-model="ruleForm.leftButtonName" class="input" placeholder="请输入按钮文字"></el-input>
                                    </el-form-item>
                                    <el-form-item label="链接类型" prop="leftButtonType" class="form-item">
                                        <el-radio-group v-model="ruleForm.leftButtonType" class="radio-group">
                                            <el-radio :label="1">H5</el-radio>
                                            <el-radio :label="2">呼叫</el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                                    <el-form-item label="链接内容" prop="leftButtonContent" class="form-item">
                                        <el-input v-model="ruleForm.leftButtonContent" class="input" placeholder="请输入链接或者电话号码"></el-input>
                                    </el-form-item>
                                </div>
                            </div>

                            <div class="mar-tb10" style="margin-top:40px">
                                <p>按钮右：</p>
                                <div>
                                    <el-form-item label="按钮文字" prop="rightButtonName" class="form-item">
                                        <el-input v-model="ruleForm.rightButtonName" class="input" placeholder="请输入按钮文字"></el-input>
                                    </el-form-item>
                                    <el-form-item label="链接类型" prop="rightButtonType" class="form-item">
                                        <el-radio-group v-model="ruleForm.rightButtonType" class="radio-group">
                                            <el-radio :label="1">H5</el-radio>
                                            <el-radio :label="2">呼叫</el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                                    <el-form-item label="链接内容" prop="rightButtonContent" class="form-item">
                                        <el-input v-model="ruleForm.rightButtonContent" class="input" placeholder="请输入链接或者电话号码"></el-input>
                                    </el-form-item>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="right">
                        <el-divider></el-divider>
                        <div class="box">
                            <img :src="imgUrl2" >
                        </div>
                    </div>
                </div>
                <div class="section s3">
                    <div class="left">
                        <el-divider></el-divider>
                        <div>
                            <p class="mar-tb10"><span>悬浮广告</span><span class="red">*</span> <span class="annot-type">注：浮标尺寸120*120px，最多1张</span></p>
                            <el-upload
                            class="upload-demo"
                            list-type="picture-card"
                            accept=".jpg,.jpeg,.png,.JPG,.JPEG"
                            :before-upload="beforeAvatarUpload"
                            :class="{hide:hideUpload2}"
                            :action="action"
                            :headers="importHeaders"
                            :on-remove="handleRemoveSuspension"
                            :on-success="onSuccessSuspension"
                            :on-change="onChangeSuspension"
                            :on-exceed="handleExceedSuspension"
                            :limit="1"
                            :file-list="fileListSuspension">
                            <i class="el-icon-plus"></i>
                            <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
                            </el-upload>
                            <el-input v-model="ruleForm.popupLinkUrl" class="input" placeholder="请输入广告弹窗跳转url"></el-input>
                            <!-- <div class="mar-tb10" style="margin-top:40px">
                                <span>开发者ID</span> 
                                <div class="mar-tb10">
                                    <el-input v-model="ruleForm.appId" class="input" placeholder="开发者ID(AppID)"></el-input>
                                </div>
                            </div>
                            <div class="mar-tb10" >
                                <span>开发者密码</span>
                                <div class="mar-tb10">
                                    <el-input v-model="ruleForm.secret" class="input" placeholder="开发者密码(AppSecret)"></el-input>
                                </div>
                            </div>
                            <div class="mar-tb10" >
                                <span>微信公众号BIZ</span> 
                                <div class="mar-tb10">
                                    <el-input v-model="ruleForm.appuinBase64" class="input" placeholder="微信公众号BIZ"></el-input>
                                </div>
                            </div> -->
                        </div>
                        <el-form-item class="save">
                            <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
                            <el-button @click="resetForm">清空</el-button>
                        </el-form-item>
                    </div>
                    <div class="right">
                        <el-divider></el-divider>
                        <div class="box">
                            <img :src="imgUrl3" >
                        </div>
                    </div>
                </div>
            </el-form>
        </div>
        <div class="copyright">Copyright©2018-{{(new Date()).getFullYear()}} 重庆几米信息技术有限公司 版权所有</div>
    </div>
</template>

<script>
import {addOrgConfig,getOrgConfig} from '@/api/advertisingManagement';
import {getToken,getOrgId,getOrgName} from '@/utils/cookie';
    export default {
        data() {
            return {
                hideUpload: false,
                hideUpload2: false,
                limitCount:5,
                limitCount2:1,
                imgUrl1:require("@/assets/image/admBoxImg/1.jpg"),
                imgUrl2:require("@/assets/image/admBoxImg/2.jpg"),
                imgUrl3:require("@/assets/image/admBoxImg/3.jpg"),
                orgName:'',
                action:process.env.VUE_APP_CURENV == 'development'?'http://172.26.202.38:8050/file/upload':'/api/file/upload',
                imglength:0,
                importHeaders: {token: getToken()},
                fileList:[],
                orgId:'',
                fileListSuspension:[],
                labelPosition:'top',
                ruleForm: {
                    orgId:'',
                    topTitleContext: '',//顶部标题
                    leftButtonName:'',//左按钮名字
                    leftButtonContent:'',//左按钮内容
                    leftButtonType:1,//左类型
                    rightButtonContent:'',//右按钮内容
                    rightButtonName:'',//右按钮名字
                    rightButtonType:1,//右类型
                    popupImageUrl:'',//广告弹窗图片url
                    popupLinkUrl:'',//广告弹窗跳转url
                    elementList:[],
                    // appId:'',
                    // secret:'',
                    // appuinBase64:''
                },
                fileLists:[],
                // roleOptions: [{
                //     orgName: '二级 1-1',
                //     children: [
                //         {
                //             orgName: '三级 1-1-1'
                //         }
                //     ]
                // }], // 设备分组下拉框
                rules: {
                    topTitleContext: [
                        { required: true, message: '请输入顶部标签', trigger: 'blur' },
                        { min: 1, max: 8, message: '长度在 1 到 8 个字符', trigger: 'blur' }
                    ],
                    leftButtonName:[{ required: true, message: '请输入按钮文字', trigger: 'blur' }],
                    rightButtonName:[{ required: true, message: '请输入按钮文字', trigger: 'blur' }],
                    picLinkContent: [{ required: true, message: '请输入链接或者电话号码', trigger: 'blur' }],
                }
            }
        },
        mounted() {
            this.orgName = this.$route.params.orgName?this.$route.params.orgName:getOrgName();
            this.orgId = this.$route.params.orgId?this.$route.params.orgId:getOrgId();
            this.getOrgConfig(this.orgId);
        },
        watch: {
            imglength: function (val) {
                /* var _this = this;
                _this.ruleForm.elementList = [];
                for (let index = 0; index < val; index++) {
                    _this.ruleForm.elementList.push({picLinkContent:'',picType:'',picUrl:''})
                }
                setTimeout(() => {
                    this.fileLists.forEach( (element,index) => {
                            _this.ruleForm.elementList[index].picUrl = element.response.data[0]
                    });                    
                }, 500); */
            },
        },
        methods: {
            beforeAvatarUpload(file){
                const isLt50M = file.size / 1024 / 1024 < 0.5;
                if (!isLt50M) {
                    this.$message({
                        message: "上传文件大小不能超过 500kB!",
                        type: "error"
                    });
                    return false;
                }
                return isLt50M;
            },
            //获取
            getOrgConfig (val) {
                var _this = this;
                _this.fileList = []
                _this.fileListSuspension = []
                _this.ruleForm = {
                    orgId:'',
                    topTitleContext: '',//顶部标题
                    leftButtonName:'',//左按钮名字
                    leftButtonContent:'',//左按钮内容
                    leftButtonType:1,//左类型
                    rightButtonContent:'',//右按钮内容
                    rightButtonName:'',//右按钮名字
                    rightButtonType:1,//右类型
                    popupImageUrl:'',//广告弹窗图片url
                    popupLinkUrl:'',//广告弹窗跳转url
                    elementList:[],
                    // appId:'',
                    // secret:'',
                    // appuinBase64:''
                },
                _this.ruleForm.orgId = _this.orgId;
                getOrgConfig({orgId:val}).then((res) => {
                    if (res.code == 200) {
                        if(res.data){
                            res.data.elementList.forEach( (element,index) => {
                                _this.fileList.push({url:element.picUrl});
                                this.hideUpload = _this.fileList.length >= this.limitCount;
                            })
                            _this.ruleForm = res.data;
                            if(res.data.popupImageUrl){
                                _this.fileListSuspension = [{url:res.data.popupImageUrl}]
                                this.hideUpload2 = _this.fileListSuspension.length >= this.limitCount2;
                            }
                        }
                        
                    } else {
                        this.$message.error('操作失败');
                    }
                })
            },
            //保存
            submitForm(formName) {
                var _this = this;
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        _this.ruleForm.orgId = _this.orgId;
                        addOrgConfig(this.ruleForm).then((res) => {
                            if (res.code == 200) {
                                this.$message.success('保存成功');
                            } else {
                                this.$message.error('保存失败');
                            }
                        })
                    } else {
                        return false;
                    }
                });
            },
            //清空
            resetForm() {
                this.fileList = []
                this.fileListSuspension = []
                this.ruleForm = {
                    orgId:'',
                    topTitleContext: '',//顶部标题
                    leftButtonName:'',//左按钮名字
                    leftButtonContent:'',//左按钮内容
                    leftButtonType:1,//左类型
                    rightButtonContent:'',//右按钮内容
                    rightButtonName:'',//右按钮名字
                    rightButtonType:1,//右类型
                    popupImageUrl:'',//广告弹窗图片url
                    popupLinkUrl:'',//广告弹窗跳转url
                    elementList:[],
                    // appId:'',
                    // secret:'',
                    // appuinBase64:''
                }
            },
            /**
             * 点击节点时传入改节点
             */
            currentNode(data) {
                this.orgId = data.orgId;
                this.getOrgConfig(this.orgId);
            },
            /** 删除照片 */
            handleRemove(file, fileList) {
                this.hideUpload = fileList.length >= this.limitCount;
                this.imglength = fileList.length;
                this.fileLists = fileList;
                this.incrAndDesc(fileList.length,file);
            },
            /** 上传成功之前 */
            onChange(file,fileList) {
                this.hideUpload = fileList.length >= this.limitCount;
                // const isPNG = (file.raw.type === "image/png" || file.raw.type === "image/jpeg");
                // const isLt5M = file.size / 1024 / 1024 < 5;
                // if (!isLt5M) {
                //     this.$message.closeAll();
                //     this.$message.error('上传头像图片大小不能超过 5MB!');
                //     fileList.pop();
                //     return false;
                // } else if (!isPNG) {
                //     this.$message.closeAll();
                //     this.$message.error('请上传jpg和png格式图片');
                //     fileList.pop();
                //     return false;
                // }
            },
            /** 超出文件个数 */
            handleExceed() {
                this.$message.warning('最多上传5张图片！');
            },
            //上传成功的回调
            onSuccess (response, file, fileList) {
                this.imglength = fileList.length;
                this.fileLists = fileList;
                this.incrAndDesc(fileList.length,file,response);
            },
            /** 图片与dom结构的增减
             * @param {Number} val 图片数组长度
             * @param {Object} res 上传成功的图片的返回数据
             * @param {Object} file 图片集合
             * @param {Number} num radio选中值
             */
            incrAndDesc(val,file, res) {
                if (res) {
                    var obj = {picLinkContent:'',picType: 1,picUrl:''};
                    if (res.code == 200 && res.data) {
                        obj.picUrl = res.data[0];
                        this.ruleForm.elementList.push(obj);
                    }
                } else {
                    this.ruleForm.elementList = this.ruleForm.elementList.filter((val) => {
                         return val.picUrl != file.url;
                    });
                }
            },

            /** 删除照片 */
            handleRemoveSuspension(file, fileList) {
                this.hideUpload2 = fileList.length >= this.limitCount2;
                this.ruleForm.popupImageUrl = ''
            },
            /** 上传成功之前 */
            onChangeSuspension(file,fileList) {
                this.hideUpload2 = fileList.length >= this.limitCount2;
                // const isPNG = (file.raw.type === "image/png" || file.raw.type === "image/jpeg");
                // const isLt5M = file.size / 1024 / 1024 < 5;
                // if (!isLt5M) {
                //     this.$message.closeAll();
                //     this.$message.error('上传头像图片大小不能超过 5MB!');
                //     fileList.pop();
                //     return false;
                // } else if (!isPNG) {
                //     this.$message.closeAll();
                //     this.$message.error('请上传jpg和png格式图片');
                //     fileList.pop();
                //     return false;
                // }
            },
            /** 超出文件个数 */
            handleExceedSuspension() {
                this.$message.warning('最多上传1张图片！');
            },
            //上传成功的回调
            onSuccessSuspension (response, file, fileList) {
                 this.ruleForm.popupImageUrl = response.data[0]
            },
            blurInput(e) {
               /*  var dom = e.target;
                var parent = dom.parentNode.parentNode.parentNode;
                if (!dom.value) {
                    parent.className += ' is-error';
                    dom.parentNode.nextSibling.style.display = 'block';
                } else {
                    parent.className = 'el-form-item';
                    dom.parentNode.nextSibling.style.display = 'none';
                }  */
            }
        },
        destroyed() {
        }
    }
</script>
<style lang="scss" scoped>
.copyright{
    clear: both;
}
.box {
    width: 100%;
    height: 100%;
}
.clearfix:after{
     content:".";        
     display:block;        
     height:0;        
     clear:both;        
     visibility:hidden;        

}
.admBox{
    float: left;
    width: calc(100% - 60px);
    max-height: 92%;
    background-color: #fff;
    border-radius: 10px;
    padding: 20px 30px;
    overflow-y:auto;
    .left{
        float: left;
        width: 50%;
    }
    .right{
        float: right;
        width: 50%;
        >.box{
            margin-bottom: 25px;
            img{
                width: 250px;
            }
        }
    }
    .section{
        overflow: hidden;
    }
    .s1{
        min-height: 700px;
    }
    .s2{
        min-height: 700px;
    }
}
.input {
    width: 300px;
    // margin-left: 10px;
}
.title {
    font-size: 26px;
    // padding: 20px 30px;
}
.form-item {
    position: relative;
}
.annotation {
    position: absolute;
    top: -45px;
    left: 80px;
    color: red;
}
.red {
    color: red;
}
.radio-group {
    margin-left: 10px;
}
.btn-input {
    width: 200px;
}
.mar-left10 {
    margin-left: 10px;
}
.mar-tb10 {
    margin: 10px 0;
    font-size: 14px;
}
.save {
    margin-top: 20px;
}
.annot-type {
    color: red;
    margin-left: 20px;
}
.error-tip {
    display: none;
}
</style>
<style>
.hide .el-upload--picture-card {
	display: none;
}
</style>
